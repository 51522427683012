// NPM
import * as React from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'

// GraphQL
import { graphql } from 'gatsby';

// Components
import Layout from "src/layouts/en"
import Home from 'src/components/pages/Home/Home';

// Font Awesome
library.add(fas)

const IndexPageFr = ({ data, location }) => {
  const topNav = data.topNav;
  const sections = _.get(data, `indexPage.sections`, []);
  const testimonials = _.get(data, `testimonials.nodes`, []);
  const bottomNav = data.bottomNav
  const socialMedia = _.get(data, `socialMedia.nodes`, [])

  return (
    <Layout 
      location={ location }
      topNav={ topNav }
      page={ data.indexPage }
      bottomNav={ bottomNav }
      socialMedia={ socialMedia }>
        <Home 
          sections={ sections }
          testimonials={ testimonials }
        />
    </Layout>
  )
}

export const query = graphql`  
  query IndexPageFr {
    topNav: strapiTopNav(locale: {eq: "fr"}) {
      ...topNavContent
    }

    indexPage: strapiPage(locale: {eq: "fr"}, uuid: {eq: "home"}) {
      ...pageInformation
    }

    testimonials: allStrapiTestimonial(filter: {locale: {eq: "fr"}}) {
      nodes {
        name
        quote
        credit
        order
      }
    }

    bottomNav: strapiBottomNav(locale: {eq: "fr"}) {
      ...bottomNavContent
    }

    socialMedia: allStrapiSocialMedia(filter: {locale: {eq: "fr"}}) {
      nodes {
        ...socialMedia
      }
    }
  }
`

export default IndexPageFr